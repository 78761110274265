"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.recursiveSchema = recursiveSchema;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread2"));
function getName(_ref) {
  var field = _ref.field,
    key = _ref.key,
    schemaProps = _ref.schemaProps;
  var name = field.name || key.toString();
  if (field.name === 'root' && schemaProps && schemaProps.name) name = schemaProps.name;
  return name;
}
function _updateField() {
  var pr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var data = pr.data,
    i = pr.i,
    fieldsName = pr.fieldsName,
    f = pr.f,
    p = pr.p;
  var allTypeField = pr.allTypeField || {};
  if (!allTypeField.fields) allTypeField.fields = {};
  var dinamicFields = f.autoFields && f.autoFields.enable && f.autoFields.enable.dinamic ? f.autoFields.enable.dinamic((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
    i: i
  })) : {
    fields: {}
  };
  var name = typeof fieldsName == 'string' && data[fieldsName] ? data[fieldsName] : typeof fieldsName == 'function' ? fieldsName(data, p) : null;
  var r = (0, _objectSpread2["default"])((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, allTypeField), dinamicFields), {}, {
    fields: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, allTypeField.fields), dinamicFields.fields),
    updateField: function updateField() {
      return _updateField({
        data: data,
        i: i,
        fieldsName: fieldsName,
        f: f,
        p: p,
        allTypeField: allTypeField
      });
    }
  });
  if (name) r.name = name;
  return r;
}
function getFields() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var field = p.field,
    type = p.type,
    data = p.data,
    key = p.key,
    fieldsName = p.fieldsName;
  var f = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, field), {}, {
    fields: field.fields ? (0, _objectSpread2["default"])({}, field.fields) : {}
  });
  if (type === 'array' || f.autoFields) {
    var allTypeField = f.autoFields && f.autoFields.enable && f.autoFields.enable["default"] ? f.autoFields.enable["default"] : field.fields[0];
    var excepts = f.autoFields && f.autoFields.except ? f.autoFields.except : {};
    if (!f.fields) f.fields = {};
    if (data[key].length === undefined) {
      Object.keys(data[key]).map(function (i) {
        var except = !!excepts[i];
        if (!except && typeof excepts["default"] == 'function') {
          except = excepts["default"]({
            data: data[key][i]
          });
        }
        if (!except) {
          if (!f.fields[i]) f.fields[i] = _updateField({
            data: data[key][i],
            i: i,
            fieldsName: fieldsName,
            f: f,
            p: p,
            allTypeField: allTypeField
          });
          if (typeof fieldsName == 'string' && data[key][i][fieldsName]) f.fields[i].name = data[key][i][fieldsName];
          if (typeof fieldsName == 'function') f.fields[i].name = fieldsName(data[key], p);
        }
      });
    } else {
      data[key].map(function (k, i) {
        var except = !!excepts[i];
        if (!except && typeof excepts["default"] == 'function') {
          except = excepts["default"]({
            data: data[key][i]
          });
        }
        if (!except) {
          f.fields[i] = _updateField({
            data: data[key][i],
            i: i,
            fieldsName: fieldsName,
            f: f,
            p: p,
            allTypeField: allTypeField
          });
        }
      });
    }
  }
  return f;
}
function getRecursiveItem(p) {
  var data = p.data,
    key = p.key,
    innerFormType = p.innerFormType,
    schemas = p.schemas,
    schemaProps = p.schemaProps,
    datas = p.datas,
    deep = p.deep,
    getItem = p.getItem;
  var field = p.field && p.field.updateField ? p.field.updateField() : p.field;
  var r = [];
  var type = field.type || 'string';
  var name = getName({
    field: field,
    key: key,
    schemaProps: schemaProps
  });
  var fieldsName = field.fieldsName;
  var def = field["default"];
  var hidden = field.hidden;
  var add = field.add;
  var remove = field.remove;
  var innerFormProps = field.innerFormProps || {};
  var parentData = p.parentData && field.parentData ? field.parentData : null;
  if (!parentData && type !== 'number' && type !== 'boolean' && !data[key] || type === 'number' && !data[key] && data[key] !== 0 || type === 'boolean' && !data[key] && data[key] !== false) {
    if (def || def === false || def === 0 || def === '') {
      data[key] = def;
    } else {
      if (type === 'number') data[key] = 0;
      if (type === 'string') data[key] = '';
      if (type === 'object') data[key] = {};
      if (type === 'array') data[key] = [];
      if (type === 'boolean') data[key] = false;
    }
  }
  if (field.fields) {
    var f = getFields((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
      field: field,
      type: type,
      data: data,
      key: key,
      fieldsName: fieldsName
    }));
    var sendData = {
      data: data[key],
      schema: f,
      innerFormType: innerFormType,
      getItem: getItem,
      schemas: schemas,
      schemaProps: schemaProps,
      datas: datas,
      deep: deep,
      key: key,
      parentData: p
    };
    if (parentData) sendData = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, sendData), {}, {
      data: p.parentData.data[p.parentData.key],
      datas: p.parentData.datas,
      deep: p.parentData.deep,
      key: p.parentData.key
    });
    if (hidden) {
      r.push.apply(r, (0, _toConsumableArray2["default"])(recursiveSchema(sendData)));
    } else {
      r.push(getItem({
        type: innerFormType,
        attr: (0, _objectSpread2["default"])({
          title: name,
          formdata: recursiveSchema(sendData),
          recursiveData: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
            getRecursiveItem: getRecursiveItem,
            getName: getName
          }),
          add: add,
          remove: typeof remove == 'function' ? remove({
            data: data,
            key: key,
            field: field,
            schemaProps: schemaProps,
            datas: datas,
            deep: deep
          }) : remove
        }, innerFormProps),
        data: data,
        key: key,
        field: field,
        schemaProps: schemaProps,
        datas: datas,
        deep: deep
      }));
    }
  } else {
    if (type === 'object' || type === 'array') {
      var _sendData = {
        data: data[key],
        schema: field,
        innerFormType: innerFormType,
        getItem: getItem,
        schemas: schemas,
        schemaProps: schemaProps,
        datas: datas,
        deep: deep,
        key: key,
        parentData: p
      };
      if (parentData) _sendData = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _sendData), {}, {
        data: p.parentData.data[p.parentData.key],
        datas: p.parentData.datas,
        deep: p.parentData.deep,
        key: p.parentData.key
      });
      r.push.apply(r, (0, _toConsumableArray2["default"])(recursiveSchema(_sendData)));
    } else {
      r.push(getItem({
        label: name,
        data: data,
        key: key,
        field: field,
        schemaProps: schemaProps,
        datas: datas,
        deep: deep
      }));
    }
  }
  return r;
}
function getDatas(_ref2) {
  var data = _ref2.data,
    datas = _ref2.datas,
    deep = _ref2.deep,
    key = _ref2.key;
  var ud = datas ? datas : {};
  if (!ud[deep]) ud[deep] = {};
  var k = key ? key : 'root';
  ud[deep][k] = data;
  return ud;
}
function getSchemaProps(_ref3) {
  var schema = _ref3.schema,
    data = _ref3.data,
    schemas = _ref3.schemas,
    schemaProps = _ref3.schemaProps;
  var s = schema ? schema : data.jsoneditor && data.jsoneditor.schema && schemas[data.jsoneditor.schema] ? schemas[data.jsoneditor.schema] : null;
  var sp = data.jsoneditor ? data.jsoneditor : schemaProps ? schemaProps : null;
  return {
    schema: s,
    schemaProps: sp
  };
}
function getReturnObject() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var data = p.data,
    schema = p.schema,
    innerFormType = p.innerFormType,
    getItem = p.getItem,
    datas = p.datas,
    schemaProps = p.schemaProps,
    key = p.key,
    _p$deep = p.deep,
    deep = _p$deep === void 0 ? 0 : _p$deep,
    _p$innerFormProps = p.innerFormProps,
    innerFormProps = _p$innerFormProps === void 0 ? {} : _p$innerFormProps;
  var e = [];
  var r = e;
  if (data.jsoneditor && data.jsoneditor.name && !schema.hidden) {
    var attr = (0, _objectSpread2["default"])({
      title: data.jsoneditor.name,
      formdata: []
    }, innerFormProps);
    r = attr.formdata;
    e.push(getItem({
      type: innerFormType,
      attr: attr,
      data: data,
      field: schema,
      schemaProps: schemaProps,
      datas: datas,
      deep: deep,
      key: key
    }));
  }
  return {
    e: e,
    r: r
  };
}
function recursiveWithoutSchema(_ref4) {
  var data = _ref4.data,
    innerFormType = _ref4.innerFormType,
    schemas = _ref4.schemas,
    schemaProps = _ref4.schemaProps,
    getItem = _ref4.getItem,
    parentData = _ref4.parentData;
  var r = [];
  var type = data.length ? 'array' : 'object';
  var keys = type === 'array' ? (0, _toConsumableArray2["default"])(data.keys(data)) : Object.keys(data);
  keys.map(function (key) {
    var item = data[key];
    if (item && typeof item == 'object') {
      r.push.apply(r, (0, _toConsumableArray2["default"])(recursiveSchema({
        data: item,
        key: key,
        innerFormType: innerFormType,
        getItem: getItem,
        schemas: schemas,
        schemaProps: schemaProps,
        parentData: parentData
      })));
    }
  });
  return r;
}
function recursiveSchema() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var data = p.data,
    _p$innerFormType = p.innerFormType,
    innerFormType = _p$innerFormType === void 0 ? 'innerform' : _p$innerFormType,
    getItem = p.getItem,
    schemas = p.schemas,
    _p$deep2 = p.deep,
    deep = _p$deep2 === void 0 ? 0 : _p$deep2,
    key = p.key,
    parentData = p.parentData;
  var _getSchemaProps = getSchemaProps({
      schema: p.schema,
      schemaProps: p.schemaProps,
      data: data,
      schemas: schemas
    }),
    schema = _getSchemaProps.schema,
    schemaProps = _getSchemaProps.schemaProps;
  var datas = getDatas({
    data: data,
    deep: deep,
    key: key,
    datas: p.datas
  });
  var _getReturnObject = getReturnObject({
      data: data,
      schema: schema,
      innerFormType: innerFormType,
      getItem: getItem,
      datas: datas,
      schemaProps: schemaProps,
      key: key,
      field: schema,
      deep: deep,
      innerFormProps: {
        recursiveData: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
          field: schema,
          datas: datas,
          schemaProps: schemaProps,
          getRecursiveItem: recursiveSchema
        })
      }
    }),
    e = _getReturnObject.e,
    r = _getReturnObject.r;
  if (schema && schema.fields) {
    Object.keys(schema.fields).map(function (key) {
      var field = schema.fields[key];
      r.push.apply(r, (0, _toConsumableArray2["default"])(getRecursiveItem({
        field: field,
        data: data,
        key: key,
        innerFormType: innerFormType,
        schemas: schemas,
        schemaProps: schemaProps,
        datas: datas,
        deep: deep + 1,
        getItem: getItem,
        parentData: parentData
      })));
    });
  } else {
    r.push.apply(r, (0, _toConsumableArray2["default"])(recursiveWithoutSchema({
      data: data,
      innerFormType: innerFormType,
      schemas: schemas,
      schemaProps: schemaProps,
      getItem: getItem,
      parentData: parentData
    })));
  }
  return e;
}