"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var messages = {
  successMessage: "Vellykket spørring",
  invalidData: "Ugyldig data",
  error: "Noe gikk galt"
};
var _default = messages;
exports["default"] = _default;