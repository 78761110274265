"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clearHistory = clearHistory;
exports.createPlayerContext = createPlayerContext;
exports.findParentView = findParentView;
exports.findRecursiveParentView = findRecursiveParentView;
exports.getChangedFilter = getChangedFilter;
exports.getConfiguration = getConfiguration;
exports.getDataAndInitialConfiguration = getDataAndInitialConfiguration;
exports.getFilteredHotspotsAndChangedFilter = getFilteredHotspotsAndChangedFilter;
exports.getFiltersData = getFiltersData;
exports.getHistory = getHistory;
exports.getHotspotsByView = getHotspotsByView;
exports.getHotspotsData = getHotspotsData;
exports.getHotspotsObjectType = getHotspotsObjectType;
exports.getInitialConfiguration = getInitialConfiguration;
exports.getIsAuthorOrAdmin = getIsAuthorOrAdmin;
exports.getMetas = getMetas;
exports.getMetasByType = getMetasByType;
exports.getObjectData = getObjectData;
exports.getObjects = getObjects;
exports.getPathNameFromView = getPathNameFromView;
exports.getSavedFilters = getSavedFilters;
exports.getSettings = getSettings;
exports.getStartView = getStartView;
exports.getTranslations = getTranslations;
exports.getViewData = getViewData;
exports.getViewObjectData = getViewObjectData;
exports.getViewObjectsFromObjectId = getViewObjectsFromObjectId;
exports.getViews = getViews;
exports.getViewsToSelectOptions = getViewsToSelectOptions;
exports.setConfiguration = setConfiguration;
exports.translate = translate;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread2"));
var _getUtils = _interopRequireDefault(require("wapplr-react/dist/common/Wapp/getUtils"));
var _utils = require("wapplr/dist/common/utils");
var translations;
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
function getTranslations(_ref) {
  var externalContext = _ref.externalContext,
    data = _ref.data;
  if (!translations) {
    var translationsFromData = getSettings({
      data: data
    }).translations || [];
    var translationsFromAttributes = [];
    try {
      var _externalContext$exte;
      translationsFromAttributes = JSON.parse(((_externalContext$exte = externalContext.externalSettings) === null || _externalContext$exte === void 0 ? void 0 : _externalContext$exte.translations) || '[]');
    } catch (e) {
      return {
        error: {
          message: 'Can\'t parse translations from attributes',
          code: 1106,
          originalError: e
        }
      };
    }
    translations = {
      lang_1: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, translationsFromData.reduce(function (o, translation) {
        if (translation.text && translation.translate) {
          o[translation.text] = translation.translate;
        }
        return o;
      }, {})), [{
        'text': 'lang_1',
        'translate': 'Norsk'
      }, {
        'text': 'available',
        'translate': 'Ledig'
      }, {
        'text': 'reserved',
        'translate': 'Reservert'
      }, {
        'text': 'sold',
        'translate': 'Solgt'
      }, {
        'text': 'Object name',
        'translate': 'Enhetsnavn'
      }, {
        'text': 'Status',
        'translate': 'Status'
      }, {
        'text': 'Number of flats',
        'translate': 'Antall enheter'
      }, {
        'text': 'Price',
        'translate': 'Pris'
      }, {
        'text': 'Floor',
        'translate': 'Etasje'
      }, {
        'text': 'Size',
        'translate': 'BRA'
      }, {
        'text': 'Rooms',
        'translate': 'Soverom'
      }, {
        'text': 'PDF download',
        'translate': 'Las ned plantekning'
      }, {
        'text': 'sguid',
        'translate': 'ID'
      }, {
        'text': 'open',
        'translate': 'Åpen'
      }, {
        'text': 'download',
        'translate': 'Last ned'
      }, {
        'text': 'Make an offer',
        'translate': 'Gi bud'
      }, {
        'text': 'Print',
        'translate': 'Trykk'
      }, {
        'text': 'Interest',
        'translate': 'Meld interesse'
      }, {
        'text': 'First name',
        'translate': 'Fornavn'
      }, {
        'text': 'Last name',
        'translate': 'Etternavn'
      }, {
        'text': 'E-mail',
        'translate': 'E-mail'
      }, {
        'text': 'Phone',
        'translate': 'Telefonnummer'
      }, {
        'text': 'Address',
        'translate': 'Adresse'
      }, {
        'text': 'Zip',
        'translate': 'Post kode'
      }, {
        'text': 'Comment',
        'translate': 'Kommentar'
      }, {
        'text': 'City',
        'translate': 'By'
      }, {
        'text': 'Privacy policy',
        'translate': 'Personvernpolicy'
      }, {
        'text': 'Submit',
        'translate': 'Sende'
      }, {
        'text': 'Cancel',
        'translate': 'Avbryt'
      }, {
        'text': 'Close',
        'translate': 'Lukke'
      }, {
        'text': 'Invalid data',
        'translate': 'Ugyldig data'
      }, {
        'text': 'Error',
        'translate': 'Noe gikk galt'
      }].reduce(function (o, translation) {
        if (translation.text && translation.translate) {
          o[translation.text] = translation.translate;
        }
        return o;
      }, {}))
    };
    if (Array.isArray(translationsFromAttributes)) {
      translationsFromAttributes.forEach(function (translation) {
        if (translation.text && translation.translate) {
          translations.lang_1[translation.text] = translation.translate;
        }
      });
    } else {
      Object.keys(translationsFromAttributes).forEach(function (langKey) {
        if (!translations[langKey]) {
          translations[langKey] = (0, _objectSpread2["default"])({}, translationsFromData.reduce(function (o, translation) {
            if (translation.text && translation.translate) {
              o[translation.text] = translation.translate;
            }
            return o;
          }, {}));
        }
        translationsFromAttributes[langKey].forEach(function (translation) {
          if (translation.text && translation.translate) {
            translations[langKey][translation.text] = translation.translate;
          }
        });
      });
    }
  }
  return translations;
}
function translate(_ref2) {
  var externalContext = _ref2.externalContext,
    data = _ref2.data,
    _ref2$selectedLang = _ref2.selectedLang,
    selectedLang = _ref2$selectedLang === void 0 ? 'lang_1' : _ref2$selectedLang,
    text = _ref2.text;
  var translations = getTranslations({
    externalContext: externalContext,
    data: data
  });
  if (!translations[selectedLang]) {
    return {
      error: {
        message: 'Invalid language configuration',
        code: 1104
      }
    };
  }
  if (!text) {
    return '';
  }
  return translations[selectedLang][text] || translations[selectedLang][text.toLowerCase()] || translations[selectedLang][capitalizeFirstLetter(text)] || text;
}
function setConfiguration(_ref3) {
  var newConfiguration = _ref3.newConfiguration,
    configuration = _ref3.configuration,
    data = _ref3.data,
    isAuthorOrAdmin = _ref3.isAuthorOrAdmin,
    onChange = _ref3.onChange,
    storage = _ref3.storage,
    externalContext = _ref3.externalContext,
    context = _ref3.context,
    appContext = _ref3.appContext,
    force = _ref3.force;
  Object.keys(configuration).forEach(function (key) {
    if (typeof newConfiguration[key] === 'undefined') {
      newConfiguration[key] = configuration[key];
    }
  });
  var validNewConfiguration = getConfiguration({
    data: data,
    savedConfiguration: newConfiguration,
    isAuthorOrAdmin: isAuthorOrAdmin,
    externalContext: externalContext,
    context: context,
    appContext: appContext
  });
  if (!validNewConfiguration.error) {
    try {
      if (JSON.stringify(validNewConfiguration) !== JSON.stringify(configuration) || force) {
        storage({
          configuration: validNewConfiguration
        });
        if (onChange) {
          onChange(JSON.parse(JSON.stringify(validNewConfiguration)));
        }
      } else {
        if (isAuthorOrAdmin) {
          console.log('[APP] It tried update the configuration but no changes...');
        }
      }
    } catch (e) {
      console.error({
        message: 'An error occurred while saving ...',
        code: 1004,
        originalError: e
      });
    }
  }
  if (validNewConfiguration.error) {
    if (validNewConfiguration.error.code < 1100) {
      if (isAuthorOrAdmin) {
        console.error(validNewConfiguration.error);
      }
    } else {
      console.warn(validNewConfiguration.error);
    }
  }
}
function getConfiguration() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var data = p.data,
    _p$savedConfiguration = p.savedConfiguration,
    savedConfiguration = _p$savedConfiguration === void 0 ? {} : _p$savedConfiguration,
    externalContext = p.externalContext,
    context = p.context,
    appContext = p.appContext;
  var storage = appContext.storage;
  var wapp = context.wapp,
    res = context.res;
  var views = getViews(p);
  if (!(views !== null && views !== void 0 && views.length)) {
    return {
      error: {
        message: 'There are no views',
        code: 1000
      }
    };
  }
  var viewIdFromUrl = res.wappResponse.route.params.viewId || res.wappResponse.route.params.objectId;
  var utils = (0, _getUtils["default"])(context);
  var initialState = typeof window !== "undefined" && window[wapp.config.appStateName] || {
    req: {
      timestamp: Date.now()
    }
  };
  var firstRender = utils.getGlobalState("req.timestamp") === initialState.req.timestamp || wapp.target === "node";
  if (firstRender && viewIdFromUrl && savedConfiguration.selectedView && viewIdFromUrl !== savedConfiguration.selectedView) {
    var viewDataFromUrl = getViewData({
      data: data,
      view_id: viewIdFromUrl
    });
    if (viewDataFromUrl) {
      console.warn({
        message: 'Different view in url and configuration, url:' + viewIdFromUrl + ', configuration:' + savedConfiguration.selectedView,
        code: 1101
      });
      savedConfiguration.selectedView = viewDataFromUrl.key;
      storage({
        configuration: savedConfiguration
      });
    }
  } else if (firstRender && viewIdFromUrl && !savedConfiguration.selectedView) {
    var _viewDataFromUrl = getViewData({
      data: data,
      view_id: viewIdFromUrl
    });
    if (_viewDataFromUrl) {
      savedConfiguration.selectedView = _viewDataFromUrl.key;
    }
  }
  var selectedLang = savedConfiguration.selectedLang,
    selectedView = savedConfiguration.selectedView,
    savedFilters = savedConfiguration.savedFilters,
    savedControls = savedConfiguration.savedControls;
  var view = selectedView ? views.filter(function (view) {
    return view.key === selectedView;
  })[0] : views[0];
  if (selectedView && !view) {
    return {
      error: {
        message: 'Outdated configuration, there is not view with that key: ' + selectedView,
        code: 1100
      }
    };
  }
  var languages = Object.keys(getTranslations({
    externalContext: externalContext,
    data: data
  }));
  var validFilters = savedFilters ? Object.keys(savedFilters).reduce(function (o, object_id) {
    var savedFilter = savedFilters[object_id];
    var view = object_id === 'availableUnits' || object_id === 'buildings' ? null : getViewObjectsFromObjectId({
      data: data,
      object_id: object_id
    }).find(function (view) {
      return view === null || view === void 0 ? void 0 : view.key;
    });
    var view_id = view === null || view === void 0 ? void 0 : view.key;
    if (savedFilter && view_id || savedFilter && object_id === 'availableUnits' || savedFilter && object_id === 'buildings') {
      var filter = getFiltersData((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
        view_id: view_id,
        listType: object_id === 'availableUnits' || object_id === 'buildings' ? object_id : ''
      }));
      var filtersDataValid = false;
      Object.keys(savedFilter.filtersData).forEach(function (type) {
        if (filter[type]) {
          Object.keys(savedFilter.filtersData[type]).forEach(function (metaKey) {
            if (filter[type][metaKey]) {
              if (filter[type][metaKey].min <= savedFilter.filtersData[type][metaKey].valueMin && filter[type][metaKey].min <= savedFilter.filtersData[type][metaKey].valueMax && filter[type][metaKey].max >= savedFilter.filtersData[type][metaKey].valueMax && filter[type][metaKey].max >= savedFilter.filtersData[type][metaKey].valueMin && savedFilter.filtersData[type][metaKey].valueMax > savedFilter.filtersData[type][metaKey].valueMin) {
                filtersDataValid = true;
              }
            }
          });
        }
      });
      if (filtersDataValid) {
        o[object_id] = {
          filtersData: savedFilter.filtersData
        };
      }
    }
    return o;
  }, {}) : {};
  var validControls = {
    filtersOpen: savedControls && typeof savedControls.filtersOpen === 'boolean' ? savedControls.filtersOpen : false,
    filtersForCurrentViewOpen: savedControls && typeof savedControls.filtersForCurrentViewOpen === 'boolean' ? savedControls.filtersForCurrentViewOpen : false,
    listType: savedControls && typeof savedControls.listType === 'string' && (savedControls.listType === 'availableUnits' || savedControls.listType === 'buildings' || savedControls.listType === '') ? savedControls.listType : 'availableUnits',
    listDisplayType: savedControls && ['grid', 'list'].indexOf(savedControls.listDisplayType) > -1 ? savedControls.listDisplayType : '',
    orderByMetas: savedControls && savedControls.orderByMetas || {}
  };
  return {
    selectedLang: languages.indexOf(selectedLang) > -1 ? selectedLang : languages[0],
    selectedView: view.key,
    savedFilters: validFilters,
    savedControls: validControls
  };
}
function getInitialConfiguration(_ref4) {
  var data = _ref4.data,
    savedData = _ref4.savedData,
    isAuthorOrAdmin = _ref4.isAuthorOrAdmin,
    externalContext = _ref4.externalContext,
    context = _ref4.context,
    appContext = _ref4.appContext;
  var initialConfiguration;
  try {
    initialConfiguration = getConfiguration({
      data: data,
      isAuthorOrAdmin: isAuthorOrAdmin,
      externalContext: externalContext,
      context: context,
      appContext: appContext
    });
    if (initialConfiguration.error) {
      if (isAuthorOrAdmin) {
        console.error({
          message: 'The player cannot be loaded',
          code: initialConfiguration.error.code,
          originalError: initialConfiguration.error
        });
      }
      initialConfiguration = null;
    }
  } catch (e) {
    if (isAuthorOrAdmin) {
      console.error({
        message: 'The player cannot be loaded',
        code: 1003,
        originalError: e
      });
    }
  }
  if (savedData !== null && savedData !== void 0 && savedData.configuration) {
    try {
      var savedConfiguration = getConfiguration({
        data: data,
        savedConfiguration: savedData.configuration,
        isAuthorOrAdmin: isAuthorOrAdmin,
        context: context,
        appContext: appContext
      });
      if (savedConfiguration.error) {
        console.warn({
          message: 'The saved configuration cannot be loaded',
          code: savedConfiguration.error.code,
          originalError: savedConfiguration.error
        });
      }
      if (!savedConfiguration.error) {
        initialConfiguration = savedConfiguration;
      }
    } catch (e) {
      console.warn({
        message: 'The saved configuration cannot be loaded',
        code: 1104,
        originalError: e
      });
    }
  }
  return initialConfiguration;
}
function fixDataElementSizes(data) {
  var views = getViews({
    data: data
  });
  function replace(_ref5) {
    var replaceElem = _ref5.replaceElem,
      image = _ref5.image,
      replaceId = _ref5.replaceId;
    var width = replaceElem.width || 1920;
    var height = replaceElem.height || 1080;
    var newWidth = image.width;
    var newHeight = image.height;
    var enableSetDimensions = image.enableSetDimensions;
    var foundRoute = enableSetDimensions ? enableSetDimensions.find(function (view) {
      return (view === null || view === void 0 ? void 0 : view.id) === replaceId;
    }) : null;
    var keepWidth = foundRoute.keepwidth;
    if (keepWidth) {
      var r = newHeight / newWidth;
      newWidth = width;
      newHeight = width * r;
    }
    if (newWidth && newHeight && newWidth !== width || newWidth && newHeight && newHeight !== height) {
      replaceElem.width = newWidth;
      replaceElem.height = newHeight;
    }
  }
  views.forEach(function (view) {
    var elements = view.elements;
    var image = elements[0].children[0].children[0].props;
    replace({
      replaceElem: elements[0].props,
      image: image,
      replaceId: 'root'
    });
    replace({
      replaceElem: elements[0].children[0].props,
      image: image,
      replaceId: 'parent'
    });
  });
}
function getDataAndInitialConfiguration() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var context = p.context,
    appContext = p.appContext,
    postContext = p.postContext,
    externalContext = p.externalContext;
  var storage = appContext.storage;
  var wapp = context.wapp,
    req = context.req;
  var isAuthorOrAdmin = getIsAuthorOrAdmin({
    context: context,
    appContext: appContext,
    postContext: postContext
  });
  var data = {};
  try {
    var _postContext$post;
    data = postContext.post && typeof postContext.post.appjson == 'string' ? JSON.parse(postContext.post.appjson) : (_postContext$post = postContext.post) !== null && _postContext$post !== void 0 && _postContext$post.appjson ? postContext.post.appjson : {};
  } catch (e) {
    console.log(e);
  }
  try {
    fixDataElementSizes(data);
  } catch (e) {
    console.log(e);
  }
  var savedData = storage();
  var query = req.wappRequest.query;
  var utils = (0, _getUtils["default"])(context);
  var initialState = typeof window !== 'undefined' && window[wapp.config.appStateName] || {
    req: {
      timestamp: Date.now()
    }
  };
  var firstRender = utils.getGlobalState().req.timestamp === initialState.req.timestamp || wapp.target === 'node';
  if (query.configuration && firstRender) {
    try {
      var configuration = JSON.parse(query.configuration);
      savedData = storage((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, Object.fromEntries(Object.keys(savedData).map(function (key) {
        return key === 'drawerOpen' ? [key, savedData[key]] : [key, undefined];
      }))), {}, {
        configuration: configuration
      }));
    } catch (e) {
      console.log(e);
    }
  }
  var initialConfiguration = p.initialConfiguration || getInitialConfiguration({
    data: data,
    savedData: savedData,
    isAuthorOrAdmin: isAuthorOrAdmin,
    externalContext: externalContext,
    context: context,
    appContext: appContext
  });
  return {
    data: data,
    initialConfiguration: initialConfiguration
  };
}
function getIsAuthorOrAdmin(_ref6) {
  var _post$author;
  var context = _ref6.context,
    appContext = _ref6.appContext,
    postContext = _ref6.postContext;
  var userStatusManager = appContext.userStatusManager;
  var user = postContext.user,
    post = postContext.post;
  var isAdmin = user && user[userStatusManager === null || userStatusManager === void 0 ? void 0 : userStatusManager._status_isFeatured];
  var isAuthor = (user === null || user === void 0 ? void 0 : user.id) && (user === null || user === void 0 ? void 0 : user.id) === (post === null || post === void 0 ? void 0 : post.author) || (user === null || user === void 0 ? void 0 : user.id) && (user === null || user === void 0 ? void 0 : user.id) === (post === null || post === void 0 ? void 0 : (_post$author = post.author) === null || _post$author === void 0 ? void 0 : _post$author.id);
  return isAdmin || isAuthor || (context === null || context === void 0 ? void 0 : context.wapp.globals.DEV);
}
function getPathNameFromView(_ref7) {
  var view_id = _ref7.view_id,
    data = _ref7.data,
    appContext = _ref7.appContext;
  var routes = appContext.routes;
  var viewData = getViewData({
    data: data,
    view_id: view_id
  });
  var firstRoute = routes.flatChooserRoute !== "/" ? routes.flatChooserRoute + "/" : "/";
  var newRoute = viewData.object_id === view_id ? firstRoute + view_id : firstRoute + viewData.object_id + "/" + view_id;
  var startView = getStartView({
    data: data
  });
  return view_id === startView ? routes.flatChooserRoute : newRoute;
}
var playerContextObject = {};
function createPlayerContextChangeHandler(_ref8) {
  var playerContext = _ref8.playerContext,
    context = _ref8.context;
  playerContext.onChange = function (configuration) {
    Object.keys(configuration).forEach(function (key) {
      playerContext.configuration[key] = configuration[key];
    });
    context.res.wappResponse.store.dispatch(context.wapp.states.runAction('app', {
      type: 'INS_APP',
      name: 'configuration',
      value: playerContext.configuration
    }));
  };
}
function createPlayerContext(_ref9) {
  var context = _ref9.context,
    appContext = _ref9.appContext,
    postContext = _ref9.postContext,
    externalContext = _ref9.externalContext;
  if (playerContextObject.current && context.wapp.target !== "node") {
    var playerContext = playerContextObject.current;
    createPlayerContextChangeHandler({
      playerContext: playerContext,
      context: context
    });
    return playerContext;
  } else {
    playerContextObject.current = {};
    var _playerContext = playerContextObject.current;
    var _getDataAndInitialCon = getDataAndInitialConfiguration({
        context: context,
        appContext: appContext,
        postContext: postContext,
        externalContext: externalContext
      }),
      initialConfiguration = _getDataAndInitialCon.initialConfiguration,
      data = _getDataAndInitialCon.data;
    _playerContext.initialConfiguration = initialConfiguration || {};
    _playerContext.configuration = (0, _utils.copyObject)(_playerContext.initialConfiguration);
    _playerContext.data = data;
    _playerContext.isAuthorOrAdmin = getIsAuthorOrAdmin({
      context: context,
      appContext: appContext,
      postContext: postContext
    });
    _playerContext.translate = function (text) {
      return translate({
        externalContext: externalContext,
        data: data,
        selectedLang: _playerContext.configuration.selectedLang,
        text: text
      });
    };
    createPlayerContextChangeHandler({
      playerContext: _playerContext,
      context: context
    });
    _playerContext.setConfiguration = function (newConfiguration) {
      return setConfiguration((0, _objectSpread2["default"])((0, _objectSpread2["default"])({
        newConfiguration: newConfiguration
      }, _playerContext), {}, {
        storage: appContext.storage,
        externalContext: externalContext,
        context: context,
        appContext: appContext
      }));
    };
    return _playerContext;
  }
}
function getViews() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var data = p.data;
  return data ? data.props.views.props.views : [];
}
function getObjects() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var data = p.data;
  return data ? data.props.objects.props.objects : [];
}
function getSettings() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var data = p.data;
  return data ? data.props.settings.props.settings[0] : {};
}
function getMetas() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var data = p.data;
  return data ? data.props.metas.props.metas : {};
}
function getObjectData() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var object_id = p.object_id;
  if (object_id) {
    var objects = getObjects(p);
    if (objects && objects.length) {
      var foundObject = null;
      objects.map(function (object) {
        if (object.key === object_id) foundObject = object;
      });
      return foundObject;
    }
  }
  return null;
}
function getViewData() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var view_id = p.view_id;
  if (view_id) {
    var views = getViews(p);
    if (views && views.length) {
      var foundView = null;
      views.map(function (view) {
        if (view.key === view_id) foundView = view;
      });
      return foundView;
    }
  }
  return null;
}
function getViewObjectData() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var view_id = p.view_id;
  var viewData = getViewData((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
    view_id: view_id
  }));
  if (viewData && viewData.object_id) {
    return getObjectData((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
      object_id: viewData.object_id
    }));
  }
  return null;
}
function getViewsToSelectOptions(p) {
  var views = getViews(p);
  var r = [];
  views.forEach(function (view) {
    var view_id = view.key;
    var viewData = getViewData((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
      view_id: view_id
    }));
    if (viewData.hidden) {
      return;
    }
    var viewName = viewData ? viewData.name : '';
    var objectData = getViewObjectData((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
      view_id: view_id
    }));
    if (objectData) {
      var name = objectData.name;
      if (viewName) {
        if (name) {
          name = name + ' [' + viewName + ']';
        } else {
          name = viewName;
        }
      }
      if (name) {
        var add = {
          value: view_id,
          label: name
        };
        r.push(add);
      }
    }
  });
  return r;
}
function getStartView(p) {
  var settings = getSettings(p);
  var startView = p.selectedView || settings.view_id;
  var views = getViews(p);
  var foundStartView = false;
  views.forEach(function (view) {
    var key = view.key;
    if (startView && key === startView) foundStartView = true;
  });
  return foundStartView ? startView : views[0] && views[0].key ? views[0].key : 'area';
}
function getHotspotsData(p) {
  var data = p.data,
    listType = p.listType;
  if (listType === 'availableUnits') {
    var hotspots = [];
    var objects = getObjects(p);
    objects.forEach(function (object) {
      if (object.type === 'flat' && object.status === 'available') {
        var object_id = object.key;
        var view = getViewObjectsFromObjectId({
          data: data,
          object_id: object_id
        }).find(function (view) {
          return view === null || view === void 0 ? void 0 : view.key;
        });
        var view_id = view === null || view === void 0 ? void 0 : view.key;
        var parent_view_id = findParentView({
          data: data,
          view_id: view_id
        });
        var defaultHotspots = getHotspotsByView({
          data: data,
          view_id: parent_view_id
        });
        hotspots.push(defaultHotspots.find(function (hotspot) {
          var _hotspot$props;
          return ((_hotspot$props = hotspot.props) === null || _hotspot$props === void 0 ? void 0 : _hotspot$props.view_id) === view_id;
        }) || {
          parent_view_id: parent_view_id,
          props: {
            effect: '',
            effect_data: '',
            enable_icon: false,
            points: [],
            view_id: view_id
          }
        });
      }
    });
    return hotspots;
  } else if (listType === 'buildings') {
    var _hotspots = [];
    var _objects = getObjects(p);
    _objects.forEach(function (object) {
      if (object.type === 'building') {
        var object_id = object.key;
        var view = getViewObjectsFromObjectId({
          data: data,
          object_id: object_id
        }).find(function (view) {
          return view === null || view === void 0 ? void 0 : view.key;
        });
        var view_id = view === null || view === void 0 ? void 0 : view.key;
        var parent_view_id = findParentView({
          data: data,
          view_id: view_id
        });
        var defaultHotspots = getHotspotsByView({
          data: data,
          view_id: parent_view_id
        });
        _hotspots.push(defaultHotspots.find(function (hotspot) {
          var _hotspot$props2;
          return ((_hotspot$props2 = hotspot.props) === null || _hotspot$props2 === void 0 ? void 0 : _hotspot$props2.view_id) === view_id;
        }) || {
          parent_view_id: parent_view_id,
          props: {
            effect: '',
            effect_data: '',
            enable_icon: false,
            points: [],
            view_id: view_id
          }
        });
      }
    });
    return _hotspots;
  } else {
    return getHotspotsByView(p);
  }
}
function getHotspotsByView() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var view_id = p.view_id,
    enableSiblingViewHotspots = p.enableSiblingViewHotspots;
  var viewData = view_id ? getViewData((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
    view_id: view_id
  })) : null;
  var hotspots = [];
  var viewObjectsFromObjectId = viewData !== null && viewData !== void 0 && viewData.object_id ? getViewObjectsFromObjectId((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
    object_id: viewData.object_id
  })) : [];
  viewObjectsFromObjectId.forEach(function (view) {
    if (view.key === view_id || enableSiblingViewHotspots) {
      var hs = view.elements[0].children[1].children[0].props.hotspots;
      hs.forEach(function (h) {
        h.parent_view_id = view.key;
      });
      hotspots.push.apply(hotspots, (0, _toConsumableArray2["default"])(hs));
    }
  });
  return hotspots;
}
function getHotspotsObjectType() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var hotspots = getHotspotsData(p);
  var r = {
    area: [],
    building: [],
    flat: [],
    notype: []
  };
  hotspots.map(function (hotspot) {
    var view_id = hotspot.props.view_id;
    var objectData = getViewObjectData((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
      view_id: view_id
    }));
    var type = objectData && objectData.type ? objectData.type : null;
    var metas = getMetasByType((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
      type: type
    }));
    if (metas && metas.length) {
      if (type == null) {
        r.notype.push(hotspot);
      } else {
        r[type].push(hotspot);
      }
    }
  });
  return r;
}
function getViewObjectsFromObjectId() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var data = p.data,
    object_id = p.object_id;
  var views = data.props.views.props.views;
  var r = [];
  if (views && views.length) {
    views.map(function (view) {
      if (view.object_id === object_id) {
        r.push(view);
      }
    });
  }
  return r;
}
function findParentView() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var data = p.data,
    view_id = p.view_id;
  var views = data.props.views.props.views;
  var foundParentView = false;
  views.map(function (view) {
    var key = view.key;
    var hotspots = getHotspotsData((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
      view_id: key
    }));
    hotspots.forEach(function (hotspot) {
      if (hotspot.props.view_id === view_id) {
        if (!foundParentView) {
          foundParentView = key;
        }
      }
    });
  });
  return foundParentView;
}
function findRecursiveParentView() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var settings = getSettings(p);
  var view_id = p.view_id,
    _p$store = p.store,
    store = _p$store === void 0 ? [] : _p$store,
    _p$callstackProtectio = p.callstackProtection,
    callstackProtection = _p$callstackProtectio === void 0 ? [] : _p$callstackProtectio;
  if (callstackProtection.indexOf(view_id) !== -1) {
    return store;
  }
  callstackProtection.push(view_id);
  var parent = findParentView((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
    view_id: view_id
  }));
  var newParents = [];
  if (parent) {
    if (store.indexOf(parent) === -1) {
      store.push(parent);
      findRecursiveParentView((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
        view_id: parent,
        store: newParents,
        callstackProtection: callstackProtection
      }));
    }
  }
  if (!newParents.length) {
    var viewData = getViewData(p);
    if (viewData) {
      var sameViews = getViewObjectsFromObjectId((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
        object_id: viewData.object_id
      })).filter(function (view) {
        return view && view.key !== view_id && view.key !== settings.view_id && store.indexOf(view.key) === -1;
      });
      if (sameViews.length) {
        sameViews.forEach(function (view) {
          if (!newParents.length) {
            findRecursiveParentView((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
              view_id: view.key,
              store: newParents,
              callstackProtection: callstackProtection
            }));
          }
        });
      }
    }
  }
  store.push.apply(store, (0, _toConsumableArray2["default"])(newParents.filter(function (viewKey) {
    return store.indexOf(viewKey) === -1;
  })));
  callstackProtection.push.apply(callstackProtection, (0, _toConsumableArray2["default"])(newParents.filter(function (viewKey) {
    return callstackProtection.indexOf(viewKey) === -1;
  })));
  return store;
}
function getMetasByType() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var type = p.type;
  var metas = getMetas(p);
  var r = [];
  metas.map(function (meta) {
    if (meta.enabled && meta.enabled.indexOf(type) > -1) {
      r.push(meta);
    }
  });
  return r;
}
function getFiltersData() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var hotspotsTypes = getHotspotsObjectType(p);
  var r = {};
  Object.keys(hotspotsTypes).map(function (key) {
    if (hotspotsTypes[key].length) {
      var type = key === 'notype' ? null : key;
      if (type) {
        var metas = getMetasByType((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
          type: type
        })).filter(function (meta) {
          return meta.show && meta.show.indexOf('filter') > -1;
        });
        r[type] = {};
        metas.map(function (meta) {
          r[type][meta.key] = {
            type: meta.type,
            name: meta.name,
            key: meta.key,
            endfix: meta.endfix,
            format: meta.format,
            formatdata: meta.formatdata,
            hotspots: hotspotsTypes[key]
          };
          hotspotsTypes[key].map(function (hotspot) {
            if (meta.type === 'number') {
              var view_id = hotspot.props.view_id;
              var objectData = getViewObjectData((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
                view_id: view_id
              }));
              if (objectData[meta.key] && !isNaN(Number(objectData[meta.key])) || objectData[meta.key] === 0) {
                var value = objectData[meta.key];
                var minValue = Math.floor(value);
                var maxValue = Math.floor(value) < value ? Math.floor(value) + 1 : Math.floor(value);
                if (!r[type][meta.key].max) {
                  r[type][meta.key].max = maxValue;
                  r[type][meta.key].valueMax = maxValue;
                }
                if (!r[type][meta.key].min) {
                  r[type][meta.key].min = minValue;
                  r[type][meta.key].valueMin = minValue;
                }
                if (maxValue > r[type][meta.key].max) {
                  r[type][meta.key].max = maxValue;
                  r[type][meta.key].valueMax = maxValue;
                }
                if (minValue < r[type][meta.key].min) {
                  r[type][meta.key].min = minValue;
                  r[type][meta.key].valueMin = minValue;
                }
              }
            }
          });
        });
      }
    }
  });
  var e = {};
  Object.keys(r).map(function (type) {
    Object.keys(r[type]).map(function (metakey) {
      var filter = r[type][metakey];
      if (filter.type === 'number' && filter.min !== filter.max) {
        if (!e[type]) e[type] = {};
        e[type][metakey] = filter;
      }
    });
  });
  return e;
}
function getFilteredHotspotsAndChangedFilter() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var filtersData = p.filtersData;
  var filteredHotspots = {};
  var newChangedFilter = 0;
  Object.keys(filtersData).map(function (type) {
    Object.keys(filtersData[type]).map(function (metaKey) {
      var meta = filtersData[type][metaKey];
      if (meta.valueMin !== meta.min || meta.valueMax !== meta.max) {
        newChangedFilter = newChangedFilter + 1;
      }
      if (meta.valueMin > meta.min || meta.valueMax < meta.max) {
        var hotspots = meta.hotspots;
        hotspots.map(function (hotspot) {
          var view_id = hotspot.props.view_id;
          var objectData = getViewObjectData((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
            view_id: view_id
          }));
          if (objectData[metaKey] < meta.valueMin || objectData[metaKey] > meta.valueMax) {
            filteredHotspots[view_id] = (0, _objectSpread2["default"])({}, objectData);
          }
        });
      }
    });
  });
  var newFilteredHotspotsNumber = Object.keys(filteredHotspots).length;
  return {
    filteredHotspotsNumber: newFilteredHotspotsNumber,
    changedFilter: newChangedFilter,
    filteredHotspots: filteredHotspots
  };
}
function getChangedFilter() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var filtersData = p.filtersData;
  var changedFilter = 0;
  Object.keys(filtersData).map(function (type) {
    Object.keys(filtersData[type]).map(function (metaKey) {
      var meta = filtersData[type][metaKey];
      if (meta.valueMin !== meta.min || meta.valueMax !== meta.max) {
        changedFilter = changedFilter + 1;
      }
    });
  });
  return changedFilter;
}
var historyMemo = {};
function clearHistory() {
  historyMemo.current = null;
}
function getHistory(_ref10) {
  var configuration = _ref10.configuration,
    data = _ref10.data;
  if (historyMemo.current) {
    return historyMemo.current;
  }
  var startView = configuration.selectedView;
  var r = {
    history: [],
    currentHistoryStep: -1
  };
  if (startView) {
    var _r$history;
    (_r$history = r.history).push.apply(_r$history, (0, _toConsumableArray2["default"])(findRecursiveParentView({
      view_id: startView,
      data: data
    }).reverse()).concat([startView]));
  }
  r.currentHistoryStep = r.history.length - 1;
  historyMemo.current = r;
  return r;
}
function getSavedFilters() {
  var _configuration$savedC;
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _p$configuration = p.configuration,
    configuration = _p$configuration === void 0 ? {} : _p$configuration,
    _p$data = p.data,
    data = _p$data === void 0 ? {} : _p$data,
    _p$savedFilters = p.savedFilters,
    savedFilters = _p$savedFilters === void 0 ? configuration.savedFilters : _p$savedFilters,
    _p$view_id = p.view_id,
    view_id = _p$view_id === void 0 ? configuration.selectedView : _p$view_id,
    _p$listType = p.listType,
    listType = _p$listType === void 0 ? (_configuration$savedC = configuration.savedControls) === null || _configuration$savedC === void 0 ? void 0 : _configuration$savedC.listType : _p$listType;
  var object_id = listType === 'availableUnits' || listType === 'buildings' ? listType : savedFilters ? Object.keys(savedFilters).find(function (object_id) {
    return getViewObjectsFromObjectId({
      data: data,
      object_id: object_id
    }).find(function (view) {
      return (view === null || view === void 0 ? void 0 : view.key) === view_id;
    });
  }) : null;
  return object_id && savedFilters[object_id] ? savedFilters[object_id] : null;
}