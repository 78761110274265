"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var menus = {
  flatChooser: 'Boligvelger',
  description: 'Beskrivelse',
  share: 'Del'
};
var _default = menus;
exports["default"] = _default;