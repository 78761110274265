"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var titles = {
  flatChooserTitle: 'Flat chooser',
  descriptionTitle: 'Description',
  shareTitle: 'Share'
};
var _default = titles;
exports["default"] = _default;