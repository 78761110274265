"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread2"));
var _formsy = _interopRequireDefault(require("./Text/formsy"));
var _formsy2 = _interopRequireDefault(require("./Toggle/formsy"));
var _validations = require("../validations");
var _default = {
  string: function string() {
    var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var label = p.label,
      data = p.data,
      key = p.key,
      _p$field = p.field,
      field = _p$field === void 0 ? {} : _p$field;
    var validations = field.validations,
      _field$multiline = field.multiline,
      multiline = _field$multiline === void 0 ? false : _field$multiline;
    var required = field.required === false || field.required === true ? field.required : null;
    var requiredUseWithDisableSubmit = required && field.requiredUseWithDisableSubmit ? required : null;
    var validationsObject = (0, _validations.getValidations)(validations, required);
    var disabled = typeof field.disabled == 'function' ? field.disabled(p) : field.disabled;
    return {
      component: _formsy["default"],
      type: 'text',
      attr: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, validationsObject), {}, {
        name: key,
        hintText: label,
        floatingLabelText: label,
        required: requiredUseWithDisableSubmit,
        multiLine: multiline,
        onChange: function onChange(state, props) {
          return function (_ref) {
            var setValue = _ref.setValue,
              value = _ref.value;
            (0, _validations.defaultValidUpdate)((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
              value: value,
              props: props,
              setValue: setValue
            }));
          };
        },
        onBlur: function onBlur(state, props) {
          return function (_ref2) {
            var setValue = _ref2.setValue,
              value = _ref2.value;
            (0, _validations.defaultValidUpdate)((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
              value: value,
              props: props,
              setValue: setValue
            }));
          };
        },
        value: function value() {
          return data[key];
        },
        disabled: disabled
      })
    };
  },
  "boolean": function boolean() {
    var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var label = p.label,
      data = p.data,
      key = p.key,
      field = p.field;
    var validations = field.validations;
    var required = field.required === false || field.required === true ? field.required : null;
    var requiredUseWithDisableSubmit = required && field.requiredUseWithDisableSubmit ? required : null;
    var validationsObject = (0, _validations.getValidations)(validations, required);
    var disabled = typeof field.disabled == 'function' ? field.disabled(p) : field.disabled;
    return {
      component: _formsy2["default"],
      type: 'toggle',
      attr: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, validationsObject), {}, {
        required: requiredUseWithDisableSubmit,
        name: key,
        label: label,
        onChange: function onChange(state, props) {
          return function (_ref3) {
            var setValue = _ref3.setValue,
              value = _ref3.value;
            var v = value === true || value === false ? value : false;
            (0, _validations.defaultValidUpdate)((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
              value: v,
              props: props,
              setValue: setValue
            }));
          };
        },
        value: function value() {
          return data[key] === false || data[key] === true ? data[key] : false;
        },
        defaultToggled: function defaultToggled() {
          return data[key] === false || data[key] === true ? data[key] : false;
        },
        disabled: disabled
      })
    };
  }
};
exports["default"] = _default;