"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var labels = {
  copyUrl: 'Kopier url',
  selectedView: 'Valgt visning',
  noImages: 'Det er ingen bilder',
  availableUnits: 'Ledige enheter',
  filter: "Filter",
  areaFilter: "Område filter",
  buildingFilter: "Bygg filter",
  flatFilter: "Leilighet filter",
  buildings: 'Velg bygg',
  previous: 'Forrige',
  next: 'Neste',
  gallery: 'Galleri',
  menuOpen: "Meny",
  search: "Søk",
  close: "Lukke",
  submit: "Sende",
  cancel: "Avbryt",
  download: "Last ned",
  languages: {
    lang_1: ''
  }
};
var _default = labels;
exports["default"] = _default;