"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var routes = {
  flatChooserRoute: '/',
  descriptionRoute: '/description',
  shareRoute: '/share'
};
var _default = routes;
exports["default"] = _default;